/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Lille"), React.createElement("p", null, "Pour se loger, Lille s’articule en quatre quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fr/lille/lille-centre-ville.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre de Lille"), " : de la Grand’Place au square Lebas, près de la gare Saint-Sauveur."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fr/lille/vieille-ville.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vieux-Lille"), ", au nord : chic et bourgeois avec ses rues pavées et ses boutiques de luxe."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fr/lille/vauban-wazemmes.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vauban-Wazemmes"), ", à l’ouest : quartier étudiant d’un côté, quartier populaire de l’autre."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/fr/lille/gare-euralille.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Euralille"), ", au nord-est : quartier moderne autour des gares de Lille-Europe et Lille-Flandres."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
